import React from "react";
import Link from "gatsby-link";
import { graphql } from "gatsby";
import get from "lodash/get";
import Helmet from "react-helmet";
import Layout from "../../components/Layout";
import Logo from "../../components/Logo";
import Navigation from "../../components/NavigationWithLogo";
import "../../style/all.css";
import Img from "gatsby-image";

class BlogIndex extends React.Component {
  render() {
    const siteTitle = get(this, "props.data.site.siteMetadata.title");

    var blatoday = new Date();
    var thisYear = blatoday.getFullYear();
    var projekteObject = {};
    var projekte = get(this, "props.data.allContentfulProject.nodes");
    function thumbnail(postNode) {
      if (postNode) {
        // console.log("this is the postNode: ", postNode)
        if (postNode.thumbnail !== null && postNode.thumbnail !== undefined) {
          return (
            <div style={{ marginLeft: 0, marginRight: "10px", padding: "0px" }}>
              <Link
                to={
                  // 'projekte/' +
                  postNode.slug
                }
                className="thumbnail-link"
                style={{
                  padding: 0,
                  margin: 0,
                  boxShadow: "none",
                  maxHeight: "150px",
                  border: "none",
                }}
              >
                <img
                  src={postNode.thumbnail.fixed.src}
                  style={{ margin: "0px", padding: "0px" }}
                  className="thumbnail-image"
                />
              </Link>
            </div>
          );
        } else {
          return (
            <div
              className="fakeThunbnail"
              style={{ width: 0, height: 0, margin: 0, padding: 0 }}
            ></div>
          );
        }
      }
    }

    projekte.forEach((node) => {
      if (!projekteObject[node.date]) {
        projekteObject[node.date] = [];
      }
      projekteObject[node.date].push(node);
    });

    return (
      <Layout>
        <Logo />
        <Navigation menuText="Projekte" />

        <div>
          <Helmet title={`Projekte | ${siteTitle}`} />
          <section className="section-projekte">
            <div className="content">
              {Object.keys(projekteObject)
                .sort(function (a, b) {
                  return b - a;
                })
                .map((year) => {
                  const postsFromThisYear = projekteObject[year];
                  if (year == thisYear) {
                    year = "Aktuelle Projekte";
                  }

                  return (
                    <div key={year}>
                      <h1>{year}</h1>
                      <div className="section-content">
                        {postsFromThisYear.map((node) => {
                          return (
                            <div
                              className="list-entry"
                              key={node.slug}
                              //   style={{margin: 0, padding: 0}}
                            >
                              {thumbnail(node)}
                              <div style={{ margin: 0 }}>
                                <h2
                                  style={{
                                    marginTop: 0,
                                    marginLeft: 0,
                                    marginRight: 0,
                                  }}
                                >
                                  <Link
                                    style={{
                                      margin: 0,
                                      verticalAlign: "top",
                                      wordBreak: "break-word",
                                    }}
                                    to={
                                      // 'projekte/' +
                                      node.slug
                                    }
                                    dangerouslySetInnerHTML={{
                                      __html: node.title,
                                    }}
                                  ></Link>
                                </h2>
                                <p
                                  dangerouslySetInnerHTML={{
                                    __html: node.kategorie?.[0]?.title ?? "",
                                  }}
                                  style={{
                                    fontStyle: "italic",
                                    paddingBottom: 0,
                                    marginBottom: "1em",
                                  }}
                                ></p>

                                <div>
                                  {node.text.childMarkdownRemark.excerpt}
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  );
                })}
            </div>
          </section>
        </div>
      </Layout>
    );
  }
}

export default BlogIndex;

export const pageQuery = graphql`
  query ProjekteQuery {
    site {
      siteMetadata {
        title
      }
    }
    allContentfulProject {
      nodes {
        date(formatString: "YYYY")
        kategorie {
          title
        }
        slug
        subtitle {
          subtitle
        }
        text {
          childMarkdownRemark {
            html
            excerpt
          }
        }
        thumbnail {
          file {
            url
          }
          fixed(width: 300, height: 300) {
            src
          }
        }
        title
        updatedAt
      }
    }
  }
`;
